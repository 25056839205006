.loadse{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.loader {
  width: 60px;
  height: 60px;
  border: 10px solid #1cd42550;
  border-top-color: #1cd425;
  animation: spin013151 1s linear infinite;
  border-radius: 100%;
  background-color: black;
 
}

@keyframes spin013151 {
  to {
    transform: rotate(360deg);
  }
}